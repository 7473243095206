.poadcast-section {
  z-index: 3;
}

@media (min-width: 1200px) {
  .poadcast-section:before {
    content: "";
    z-index: 0;
    width: 100%;
    height: 90%;
    position: absolute;

    background: linear-gradient(#294173 0%, #166885 100%);
    opacity: 0.83;
    top: 50%;
    transform: translateY(-50%);
  }
}
.poadcast-section:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 5%;
  position: absolute;
  bottom: 0;
  background: #1a1a1a;
}
.poadcastSlider-box {
  border-radius: 18px;
  position: relative;
}
.poadcastSlider-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.596);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.poadcastSlider-box:hover:after {
  opacity: 1;
}

.play-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 1;
}
.content-box {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 3;
}
.proadcastimg {
  height: 190px;
  object-fit: cover;
  border-radius: 10px;
}
.proadcastimg2 {
  height: 344px;
  border-radius: 10px;
  object-fit: cover;
}
.content-box h1 {
  font-family: "Titillium Web";
  font-weight: normal;
  font-size: 14px;
  color: #fff;
}

iframe {
  pointer-events: none;
}
