@mixin flex-box($direction: row, $align-items: center, $justify-content: center) {
	display: flex;
	flex-direction: $direction;
	align-items: $align-items;
	justify-content: $justify-content;
}
.navbar {
	@include flex-box(row, center, space-between);
	height: 10rem;
	padding: 0 2.4rem;
}
.list {
	@include flex-box(row, center, flex-start);
	list-style: none;
	column-gap: 1.2rem;
}
.item {
	position: relative;
	@include flex-box(column, center, center);
	height: 11rem;
	padding: 1.2rem 1.6rem;
	cursor: pointer;

	&::before {
		position: absolute;
		content: '';
		inset: 0;
		background-color: #3686bf;
		z-index: -1;
		transform: scaleY(0);
		transition: all 0.6s;
		transform-origin: top;
	}

	&:hover::before {
		transform: scaleY(1);
	}
}

.link {
	display: inline-block;
	text-decoration: none !important;
	color: #fff !important;
	font-size: 1.8rem;
	font-weight: 600;
	pointer-events: none;
}
.buttons {
	@include flex-box(row, center, flex-start);
	gap: 2.4rem;
}
.btn {
	@include flex-box(column, center, center);
	border: none;
	outline: none;
	font-size: 2.4rem;
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	background-color: #fff;
	color: #000;
}
