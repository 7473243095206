.footer {
  background-image: url("../../Assets/footer.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* height: 400px; */
  list-style-type: none;
}

.overlay {
  background: rgb(0, 0, 0, 0.8);
  /* height: 400px; */
  z-index: 2;
}

.leftBox {
  border-radius: 1px solid black;
  background-color: #232323;
  /* height: 400px; */
  color: #bdbec0;
  width: min-content;
}

.leftBoxDiv {
  background-color: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-right: auto;
  /* 1 */
  margin-left: auto;
  /* 1 */
  max-width: 300px;
  /* 2 */
}

.contact {
  margin-right: auto;
  /* 1 */
  margin-left: auto;
  /* 1 */
  max-width: 300px;
  /* 2 */
}

.contact span {
  text-decoration: underline;
  color: #3d98d1;
  font-size: 17px;
}

.list {
  list-style-type: none;
  padding-bottom: 20px;
}

.list a {
  color: #bdbec0;
  text-decoration: none;
}

.list li {
  padding-bottom: 10px !important;
}

.list h1 {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.copyrightText {
  color: white;
  margin-left: -400px !important;
}

@media screen and (max-width: 1280px) {
  .footer {
    /* height: 800px; */
    background-size: cover;
  }

  .overlay {
    background: rgb(0, 0, 0, 0.8);
    /* height: 800px; */
    z-index: 2;
  }
}

@media screen and (max-width: 599px) {
  .footer {
    /* height: 1150px; */
    background-size: 1180px;
  }

  .overlay {
    background: rgb(0, 0, 0, 0.8);
    /* height: 1150px; */
    z-index: 2;
  }
}
